import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Cookies from "js-cookie";
import DataContext from "../../../controllers/DataContext";
import { fetchJSON, validateJSON } from "../../../controllers/Essentials";
import SuccessEmployeeModal from "../../helpers/successEmployeeModal";
import { Typography, Box } from "@mui/material";
import closeIcon from "../../../Images/Vector.svg";
import { InputAdornment, styled, TextField } from "@mui/material";
import { ArrowBack, Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "material-ui/IconButton";
import { useEffect } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: 196,
};
function AddEmployee({ setSelectedButton }) {
  const [value, setValue] = useState("admin");
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const initialState = {
    clinicid: "",
    fullname: "",
    password: "",
    email: "",
    mobile: "",
    accesslevel: "",
  };
  function onClose() {
    setIsOpen(false);
  }
  const date = useRef();
  const [errors, setErrors] = useState({});
  const history = useNavigate();
  const { data, setData } = useContext(DataContext);
  // const [patientInfo, setPatientInfo] = React.useState([]);

  const clinic = JSON.parse(localStorage.getItem("clinic"));

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeIcon = (event) => {
    setPassword(event.target.value);
  };
  useEffect(() => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      password: password,
    }));
  }, [password]);
  const [inputs, setInputs] = useState({
    clinicid: "",
    fullname: "",
    password: "",
    email: "",
    mobile: "",
    accesslevel: "",
  });
  console.log(inputs);
  React.useEffect(() => {
    if (isOpen) {
    }
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setValue(event.target.value);
  };

  const validate = (fieldValues = inputs) => {
    let tempErrors = { ...errors };
    if ("employeeId" in fieldValues)
      tempErrors.name = fieldValues.employeeId ? "" : "This field is required.";
    if ("fullname" in fieldValues)
      tempErrors.name = fieldValues.fullname ? "" : "This field is required.";

    if ("password" in fieldValues)
      tempErrors.password = fieldValues.password
        ? ""
        : "This field is required.";

    if ("email" in fieldValues)
      tempErrors.email = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
        fieldValues.email
      )
        ? ""
        : "Email is not valid.";

    setErrors({
      ...tempErrors,
    });

    if (fieldValues === inputs)
      return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      inputs.clinicid = clinic[0];

      fetchJSON("user/create", "POST", inputs).then((data_response) => {
        setSelectedButton(1);
        if (data_response.success == true) {
          setData((prev) => {
            return {
              ...prev,
              PatientInfo: inputs,
            };
          });

          setInputs(initialState);
          setIsOpen(true);
          handleOpen(true);
        } else {
          alert("Something Went Wrong");
        }
      });
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    inputs.full_name = inputs.first_name + " " + inputs.last_name;
    if (validateJSON(inputs) === true) {
      fetchJSON("patient/update", "POST", inputs)
        .then((data_response) => {
          if (data_response.success == true) {
            localStorage.setItem(
              "patient",
              JSON.stringify([
                "id",
                inputs.clinicid,
                inputs.fullname,
                inputs.dob,
                inputs.cycle_id,
                Cookies.get("common_data").split("#")[1],
                inputs.mobile,
              ])
            );
            setIsOpen(false);
            // history("/upload-images");
          } else {
            alert("Something Went Wrong");
          }
        })
        .catch((err) => {
          alert("try again");
        });
    } else {
      alert(validateJSON(inputs));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        // height: "850px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div className="container2">
        <div className="out-box2 ">
          <div
            style={{
              position: "absolute",
              top: "108px",
              left: "25%",
              width: "100px",
              padding: 7,
            }}
          >
            <button
              onClick={() => setSelectedButton(1)}
              className="back-button"
            >
              <ArrowBack />
              Back
            </button>
          </div>
          <div className="contain1 " style={{}}>
            <div></div>
            <h2 className="txt8">Add New Employee</h2>
            <div className="in-box">
              <div>
                <label style={{ display: "block" }}>Employee ID</label>
                <input
                  className="inpt"
                  name="employeeId"
                  value={inputs.employeeId}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  fullWidth
                  {...(errors.name && { error: true, helperText: errors.name })}
                />
                <label style={{ display: "block", color: "red" }}>
                  {" "}
                  {errors.name}{" "}
                </label>
              </div>
              <div>
                <label style={{ display: "block" }}>Employee Name</label>
                <input
                  className="inpt"
                  name="fullname"
                  value={inputs.fullname}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  fullWidth
                  {...(errors.name && { error: true, helperText: errors.name })}
                />
                <label style={{ display: "block", color: "red" }}>
                  {" "}
                  {errors.name}{" "}
                </label>
              </div>
              <div style={{ marginTop: 13, marginLeft: isOpen ? "8%" : "" }}>
                <label style={{ display: "block" }}>Password</label>
                <TextField
                  className="inpt1"
                  sx={{}}
                  style={{ width: isOpen ? "90%" : "100%", height: 42 }}
                  name="password"
                  value={password}
                  onChange={handleChangeIcon}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <label style={{ display: "block" }}>Email</label>
                <input
                  className="inpt"
                  type="email"
                  name="email"
                  value={inputs.email}
                  onChange={handleChange}
                />
              </div>
              <div style={{ marginTop: 13 }}>
                <label style={{ display: "block" }}>Contact Number</label>
                <input
                  className="inpt"
                  type="tel"
                  name="mobile"
                  value={inputs.mobile}
                  onChange={handleChange}
                  defaultValue=""
                />
              </div>
              <div style={{ marginTop: 8 }}>
                <label style={{ display: "block" }}>Account Access</label>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="accesslevel"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="admin"
                      control={<Radio />}
                      label="Admin"
                    />
                    <FormControlLabel
                      value="embryologist"
                      control={<Radio />}
                      label="Embryologist"
                    />
                    <FormControlLabel
                      value="accounts"
                      control={<Radio />}
                      label="Accounts"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <button
                className="btn1"
                style={{ marginTop: 8 }}
                onClick={handleSubmit}
              >
                Save & Continue
              </button>
            </div>
          </div>
        </div>
        {open && (
          <>
            <Modal
              style={{ borderRadius: 12 }}
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  style={{ marginLeft: 67, fontSize: 28 }}
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  New employee has been added.
                </Typography>
                <div className="popup-div1">
                  <button
                    className="popup-btn1"
                    onClick={() => setSelectedButton(1)}
                  >
                    Go to Admin panel
                  </button>
                </div>
              </Box>
            </Modal>
            <div>
              <img />
            </div>
          </>
        )}
      </div>

      <Modal open={isOpen}>
        <SuccessEmployeeModal setIsOpen={setIsOpen} setIsEdit={setIsEdit} />
      </Modal>
    </div>
  );
}

export default AddEmployee;
