import { Page, StyleSheet, View, Image, Text, Font } from "@react-pdf/renderer";
import Footer from "./Footer";
import backGround from "../img/Vector 1.png";
import Logo1 from "../img/Group 9.png";
import Motherbg1 from "../img/Ellipse 1.png";
import Wave from "../img/wave.svg";
import InterRegular from "../../../fonts/Inter-Regular.ttf";
import InterBold from "../../../fonts/Inter-Bold.ttf";

const OnePage = ({ patientData, clinicData, reportData }) => {
  Font.register({
    family: "Inter",
    fonts: [
      { src: InterRegular, fontWeight: "normal" },
      { src: InterBold, fontWeight: "bold" },
    ],
  });
  return (
    <Page size="A4" style={styles.page} wrap>
      <View style={styles.Img}>
        <Image style={styles.bg} src={backGround} />
        <View style={styles.oneImg}>
          <Image src={Logo1} />
          <Image style={styles.mainImg} src={Motherbg1} />
        </View>
        <View style={styles.title}>
          <Text style={styles.titleTextStyle}>
            Artificial Intelligence enabled embryo selection for
          </Text>
          <Text style={styles.titleTextStyle}>high IVF success rate</Text>
        </View>
      </View>

      <Footer
        patientData={patientData}
        clinicData={clinicData}
        reportData={reportData}
      />
    </Page>
  );
};

const styles = StyleSheet.create({
  titleTextStyle: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 2,
    letterSpacing: 0,
    textAlign: "center",
    background: "#010B18",
    marginTop: 10,
  },
  page: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  Img: {
    height: "80vh",
    width: "100%",
    position: "relative",
    display: "flex",
    // justifyContent: 'space-evenly',
  },
  mainImg: {
    flexBasis: 650,
    marginBottom: -45,
  },
  bg: {
    position: "absolute",
    left: 0,
    height: "520.955px",
    width: "100%",
    zIndex: 3,
  },

  oneImg: {
    padding: 30,
    height: "85vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    verticalAlign: "top",
    fontSize: "16px",
    fontFamily: "Inter",
    lineHeight: "30%",
    color: "#010a18",
    marginBottom: 20,
  },
});
export default OnePage;
