import React, { useState, useEffect } from "react";
import { styled, Box, Typography, Button } from "@mui/material";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User from "../../../Icons/Admin/admin.svg";
import Patients from "../Admin/Patients";
import About from "../../../Icons/Admin/about.png";
import Help from "../../../Icons/Admin/help.png";
import View from "../../../Icons/Admin/view-patients.png";
import Accounts from "../../../Icons/Admin/accounts.png";
import Activity from "../../../Icons/Admin/activity-logs.png";
import AddPatient from "../../../Icons/add-new-patients.png";
import ViewPatients from "./ViewPatients";
import AdminForm from "./AdminForm";
import AddEmployee from "./addEmployee";
import ActivityLogs from "./ActivityLogs";
import PaymentList from "./PaymentList";
import Payment from "./Payment";
import Helps from "../Admin/help";
import Abouts from "../Admin/about";
import Report from "./report";
import { useNavigate } from "react-router-dom";
import AddPatient1 from "./addPatient";
import { useLocation } from "react-router-dom";
import ActivityDetails from "./ActivityDetails";
import Cookies from "js-cookie";

const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
  minWidth: "20%",
  maxWidth: "20%",
  height: 725,
  borderRadius: "0px 12px 12px 0px",
  marginTop: "20px",
  borderImageSource: `linear-gradient(to left, #CEE1FD 8.33%, #CCF6FF 91.67%)`,
  borderImageSlice: 1,
  border: "2px solid transparent",
  "@media (max-width: 1540px)": {
    height: "645px",
  },
});

const BoxContainer = styled(Button)({
  width: 196,
  height: 48,
  background: "rgba(255, 255, 255, 0.8)",
  borderRadius: "12px",
  display: "flex",
  alignItems: "left",
  marginTop: "40px",
  border: "none",
  outline: "none",
  justifyContent: "flex-start",
});

const BoxContainer1 = styled(Box)({
  width: "32px",
  height: "32px",
  background: "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
  borderRadius: "40px",
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-start",
  margin: "8px 12px 8px 8px",
});

const StyledTypography = styled(Typography)({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#6C7C93",
});

const Sidebar = () => {
  const location = useLocation();
  const variable = location.state && location.state.variable;
  const history = useNavigate();
  const [selectedButton, setSelectedButton] = useState(4);
  const [selectedItem, setSelectedItem] = useState(0);
  const [accessLevel, setAccessLevel] = useState(0);
  useEffect(() => {
    const user_details = localStorage.getItem("user_details");
    if (!user_details) {
      history("/");
      return;
    }
    const access_level = JSON.parse(user_details);
    setAccessLevel(access_level[5]);
  }, []);
  useEffect(() => {
    if (variable) {
      setSelectedButton(variable);
    }

    const checkLocalStorageKey = () => {
      if (!localStorage.getItem("clinic") || !Cookies.get("access_token")) {
        Cookies.set("access_token", "", { expires: -1 });
        localStorage.setItem("clinic", []);
        history("/");
      }
    };

    checkLocalStorageKey();
  }, []);

  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
  };

  const renderMenuItem = (buttonId, icon, text) => (
    <BoxContainer
      key={buttonId}
      variant="text"
      sx={{ marginTop: "40px", outlineStyle: "none" }}
      style={{
        background:
          selectedButton === buttonId
            ? "linear-gradient(360deg, #2279F5 8.33%, #00D2FE 91.67%)"
            : "rgba(255, 255, 255, 0.8)",
      }}
      onClick={() => handleButtonClick(buttonId)}
    >
      <BoxContainer1
        style={{
          background:
            selectedButton === buttonId
              ? "#FFFFFF"
              : "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
        }}
      >
        <img src={icon} alt={text} />
      </BoxContainer1>
      <StyledTypography
        sx={{
          color: selectedButton === buttonId ? "#ffffff" : "#6C7C93",
        }}
        variant="h6"
        component="h6"
      >
        {text}
      </StyledTypography>
    </BoxContainer>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <StyledContainer>
        {accessLevel === "admin" && (
          <>
            {renderMenuItem(1, User, "Admin Panel")}
            {renderMenuItem(3, AddPatient, "Add New Patient")}
            {renderMenuItem(4, View, "View Patients")}
            {renderMenuItem(6, Accounts, "Accounts")}
            {renderMenuItem(7, Activity, "Activity Logs")}
            {renderMenuItem(9, Help, "Help")}
            {renderMenuItem(10, About, "About Genesys")}
          </>
        )}
        {accessLevel === "embryologist" && (
          <>
            {renderMenuItem(3, AddPatient, "Add New Patient")}
            {renderMenuItem(4, View, "View Patients")}
            {renderMenuItem(9, Help, "Help")}
            {renderMenuItem(10, About, "About Genesys")}
          </>
        )}
        {accessLevel === "accounts" && (
          <>
            {renderMenuItem(9, Help, "Help")}
            {renderMenuItem(10, About, "About Genesys")}
          </>
        )}
      </StyledContainer>

      {selectedButton === 1 && (
        <AdminForm setSelectedButton={setSelectedButton} />
      )}
      {selectedButton === 3 && <AddPatient1 />}
      {selectedButton === 2 && (
        <AddEmployee setSelectedButton={setSelectedButton} />
      )}
      {selectedButton === 4 && (
        <ViewPatients setSelectedButton={setSelectedButton} />
      )}
      {selectedButton === 6 && (
        <Payment setSelectedButton={setSelectedButton} />
      )}
      {selectedButton === 5 && (
        <PaymentList setSelectedButton={setSelectedButton} />
      )}
      {selectedButton === 7 && (
        <ActivityLogs
          setSelectedButton={setSelectedButton}
          setSelectedItem={setSelectedItem}
        />
      )}
      {selectedButton === 8 && (
        <Patients setSelectedButton={setSelectedButton} />
      )}
      {selectedButton === 9 && <Helps />}
      {selectedButton === 10 && <Abouts />}
      {selectedButton === 11 && (
        <Report setSelectedButton={setSelectedButton} />
      )}
      {selectedButton === 12 && (
        <ActivityDetails setSelectedButton={setSelectedButton} />
      )}
    </Box>
  );
};

export default Sidebar;
