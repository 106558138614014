import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../controllers/UserContext";
import { useNavigate } from "react-router-dom";
import DataContext from "../../controllers/DataContext";
import Cookies from "js-cookie";
import LoginImage from "../../Images/login.png";
import Logo from "../../Images/Group.svg";
import Logo1 from "../../Images/Group (1).svg";
import Logo2 from "../../Images/Group (2).svg";
import "./style.css";
import Checkbox from "@mui/material/Checkbox";
import { InputAdornment, styled, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "material-ui/IconButton";
import ForgotPassword from "./ForgotPassword";
const Login = () => {
  const [inputs, setInputs] = useState({});
  const history = useNavigate();
  const { data, setData } = useContext(DataContext);
  const { updateUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  useEffect(() => {
    // Check if "Remember Me" is selected and populate input fields with stored credentials
    if (rememberMe) {
      const storedCredentials = JSON.parse(
        localStorage.getItem("storedCredentials")
      );
      if (storedCredentials) {
        setInputs(storedCredentials);
      }
    }
  }, [rememberMe]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      password: password,
    }));
  }, [password]);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeIcon = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    if (Cookies.get("access_token")) {
      history("/sidebar");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setData((prev) => {
      return {
        ...prev,
        LoginInfo: inputs,
      };
    });
    if (rememberMe) {
      localStorage.setItem("storedCredentials", JSON.stringify(inputs));
    } else {
      localStorage.removeItem("storedCredentials");
    }

    await fetch("https://api2.genesysailabs.com/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inputs),
    })
      .then((response) => response.json())
      .then((userData) => {
        updateUser(userData);
        localStorage.setItem("user_details", JSON.stringify(userData?.user));

        setData((prev) => {
          return {
            ...prev,
            LoginData: userData,
          };
        });
        if (userData.login == true) {
          Cookies.set("access_token", userData?.access_token, {
            expires: 1,
          });
          Cookies.set(
            "common_data",
            userData?.clinic[1] + "#" + userData?.user[2],
            {
              expires: 1,
            }
          );
          localStorage.setItem("clinic", JSON.stringify(userData?.clinic));
          if (userData?.user[5] === "clinic_admin") {
            history("/admin-dashboard");
          } else {
            history("/welcome");
          }
        } else {
          alert("Invalid Credentials");
        }
      })
      .catch((ERR) => {
        alert("Invalid Credentials");
      });
  };
  return (
    <div className="login-div">
      <div className="login-image-div">
        <img className="img-image" src={LoginImage} alt="image" />
      </div>
      <div className="login-container">
        <div className="login-container-img">
          <div className="head-div">
            <div className="head">Welcome to</div>
            <div className="head-box">
              <div>
                <img className="img-div-logo" src={Logo2} />
                <img
                  className="img-div"
                  src={Logo}
                  style={{ marginLeft: "13px" }}
                />
                <img
                  className="img-div"
                  src={Logo1}
                  style={{ marginLeft: "10px" }}
                />
              </div>
              <div className="svg-container">AI ENHANCED EMBRYO SELECTION</div>
            </div>
          </div>
          <div className="contain12">
            <h2 className="txt8-login">Login</h2>
            <form>
              <div className="in-box-login">
                <div>
                  <label style={{ display: "block" }}>User ID</label>
                  <input
                    className="inpt"
                    name="username"
                    value={inputs.username}
                    type="text"
                    onChange={handleChange}
                  />
                </div>

                <div style={{ marginTop: 13 }}>
                  <label style={{ display: "block" }}>Password</label>
                  <TextField
                    className="inpt-login"
                    style={{}}
                    name="password"
                    value={inputs.password}
                    onChange={handleChangeIcon}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="forgot-password-div">
                  {" "}
                  <div>
                    <Checkbox
                      style={{
                        marginRight: 10,
                      }}
                      checked={rememberMe}
                      onChange={(event) => setRememberMe(event.target.checked)}
                    />
                    Remember Me
                  </div>
                  <div
                    className="forgot-password"
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    Forgot password?
                  </div>
                </div>
                <button className="btn1-login" onClick={handleSubmit}>
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {open && (
        <ForgotPassword
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default Login;
