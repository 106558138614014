import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { InputAdornment, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { makeStyles } from "@mui/styles";
import IconButton from "material-ui/IconButton";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../controllers/DataContext";
import { fetchJSON } from "../../controllers/Essentials";

const useStyles = makeStyles({
  datePicker: {
    // Customize the border radius
    height: "42px", // Customize the height
    "& .MuiFormControl-root": {
      // Customize the input padding if needed
      borderRadius: "22px",
    },
  },
});

const EmployeeForm = ({ isOpen, setIsOpen, setDelete }) => {
  function onClose() {
    setIsOpen(false);
  }
  const date = useRef();

  const history = useNavigate();
  const { data, setData } = useContext(DataContext);
  // const [employeeinfo, setemployeeinfo] = React.useState([]);

  const employeeinfo = JSON.parse(localStorage.getItem("employee"));
  const [value, setValue] = useState(employeeinfo.accesslevel);

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeRadio = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setValue(event.target.value);
  };

  const handleChangeIcon = (event) => {
    setPassword(event.target.value);
  };
  useEffect(() => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      password: password,
    }));
  }, [password]);
  const [inputs, setInputs] = useState({
    user_id: "",
    employeeId: "",
    fullname: "",
    password: "",
    mobile: "",
    email: "",
  });

  React.useEffect(() => {
    if (isOpen) {
      console.log(employeeinfo);
      setInputs({
        user_id: employeeinfo.id,
        clinicid: employeeinfo.clinicid,
        fullname: employeeinfo.fullname,
        password: setPassword(employeeinfo.password),
        email: employeeinfo.email,
        mobile: employeeinfo.mobile,
        accesslevel: employeeinfo.accesslevel,
      });
    }
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChange1 = () => {
    console.log(date.current.name);
    //date.current= date.current.value;
    setInputs((values) => ({ ...values, dob: date.current.value }));
    //setInputs((values) => ({ ...values, dob: date }));
  };

  const handleDateChange = (date) => {
    const newValue = date.format("YYYY-MM-DD");
    console.log(newValue);
    setInputs((values) => ({ ...values, dob: newValue }));
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    fetchJSON("user/update", "POST", inputs)
      .then((data_response) => {
        if (data_response.success == true) {
          setIsOpen(false);
          // history("/upload-images");
        } else {
          alert("Something Went Wrong");
        }
      })
      .catch((err) => {
        alert("try again");
      });
    setDelete((prevState) => !prevState);
  };

  return (
    <>
      {!isOpen ? (
        <div></div>
      ) : (
        <div style={{ position: "relative" }}>
          <button
            className="close-button"
            style={{ top: 10, right: 20 }}
            onClick={onClose}
          >
            <CloseIcon sx={{ color: "#6C7C93" }} />
          </button>
          <div
            className="in-box"
            style={{
              background: "#FFFFFF",
              top: "30px",
              left: "28%",
              borderRadius: "10px",
              height: "450px",
            }}
          >
            <h2 className="txt8">Edit Employee</h2>
            {/* <div style={{ marginTop: 13, marginLeft: isOpen ? "8%" : "" }}>
              <label style={{ display: "block" }}>Employee Id</label>
              <input
                className="inpt1"
                style={{ width: isOpen ? "90%" : "100%" }}
                name="employeeId"
                value={inputs.employeeId}
                onChange={handleChange}
                type="text"
              />
            </div> */}
            <div style={{ marginTop: 13, marginLeft: isOpen ? "8%" : "" }}>
              <label style={{ display: "block" }}>Name</label>
              <input
                className="inpt1"
                style={{ width: isOpen ? "90%" : "100%" }}
                name="fullname"
                value={inputs.fullname}
                onChange={handleChange}
                type="text"
              />
            </div>

            <div style={{ marginTop: 13, marginLeft: isOpen ? "8%" : "" }}>
              <label style={{ display: "block" }}>Password</label>
              <TextField
                className="inpt1"
                style={{ width: isOpen ? "90%" : "100%", height: 42 }}
                name="password"
                value={password}
                onChange={handleChangeIcon}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div style={{ marginTop: 13, marginLeft: isOpen ? "8%" : "" }}>
              <label style={{ display: "block" }}>Email</label>
              <input
                className="inpt"
                style={{ width: isOpen ? "90%" : "100%" }}
                name="email"
                value={inputs.email}
                onChange={handleChange}
                // name="cars"
              />
            </div>
            <div style={{ marginTop: 13, marginLeft: isOpen ? "8%" : "" }}>
              <label style={{ display: "block" }}>Contact Number</label>
              <input
                className="inpt"
                style={{
                  width: isOpen ? "90%" : "100%",
                  margin: isOpen ? "0px auto" : "",
                }}
                name="mobile"
                value={inputs.mobile}
                onChange={handleChange}
                type="tel"
                placeholder="optional"
              />
            </div>
            <div style={{ marginTop: 13, marginLeft: "8%" }}>
              <label style={{ display: "block" }}>Account Access</label>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="accesslevel"
                  value={value}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel
                    value="admin"
                    control={<Radio />}
                    label="Admin"
                  />
                  <FormControlLabel
                    value="embryologist"
                    control={<Radio />}
                    label="Embryologist"
                  />
                  <FormControlLabel
                    value="accounts"
                    control={<Radio />}
                    label="Accounts"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <button
              onClick={handleEdit}
              className="btn1"
              style={{
                width: isOpen ? "50%" : "100%",
                marginLeft: isOpen ? "25%" : "",
                marginTop: isOpen ? "21px" : "",
              }}
            >
              Save & Continue
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeForm;
