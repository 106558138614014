import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CloseOutlined, Verified } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  border: "none",
  borderRadius: 4,
};

export default function ForgotPassword({ open, handleClose }) {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [error, setError] = useState();
  const [emailError, setEmailError] = useState();
  const [otpError, setOtpError] = useState();
  const [successful, setSuccessful] = useState(false);
  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds

  useEffect(() => {
    let interval;
    if (showOTPInput && !showPasswordFields) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
  }, [showOTPInput, showPasswordFields]);

  useEffect(() => {
    if (countdown === 0) {
      setSuccessful(false);
      // setShowOTPInput(false);
      setOtpError("OTP expired. Please request a new one.");
    }
  }, [countdown]);

  const handleForgotPassword = async () => {
    try {
      const response = await fetch(
        "https://api2.genesysailabs.com/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      if (response.ok) {
        setShowOTPInput(true);
        setSuccessful(true);
      } else if (response.status === 404) {
        setEmailError("Email not found. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOTPVerification = async () => {
    try {
      const response = await fetch(
        "https://api2.genesysailabs.com/verify-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, otp }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data?.access_token) {
          setAccessToken(data?.access_token);
          setShowPasswordFields(true);
          toast.success("OTP verification successful!");
        } else {
          // toast.error("Access token not found in response.");
        }
      } else {
        // toast.error("OTP verification failed. Please try again.");
        setOtpError("Invalid OTP");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangePassword = async () => {
    try {
      if (password.length < 8) {
        setError("Password must be at least 8 characters long.");
        return;
      }
      if (password !== confirmPassword) {
        setError("Passwords do not match.");
        return;
      }

      const response = await fetch(
        "https://api2.genesysailabs.com/change_password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ email, password }),
        }
      );
      if (response.ok) {
        toast.success("Password changed successfully!");
        handleClose(); // Close modal after password change
      } else {
        toast.error("Failed to change password. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Reset Your Password
            </Typography>
            <Typography onClick={handleClose}>
              <CloseOutlined />
            </Typography>{" "}
          </div>
          {!showOTPInput ? (
            <>
              <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                Please enter your email:
              </Typography>
              <input
                className="inpt"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: "100%", marginTop: 10 }}
              />
              {<div style={{ color: "red", marginTop: 8 }}>{emailError}</div>}

              <button
                style={{ marginTop: "18px" }}
                className="btn-forgot"
                onClick={handleForgotPassword}
              >
                Verify Email
              </button>
            </>
          ) : !showPasswordFields ? (
            <>
              {successful ? (
                <div className="success">
                  <div>
                    <Verified style={{ color: "green", fontSize: 40 }} />
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    A verification OTP has been send to
                  </div>
                  <div style={{ fontWeight: "bold" }}>{email}</div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      marginTop: 10,
                    }}
                  >
                    Please check your inbox and enter the verification otp below
                    to verify your email address. The code will expire in{" "}
                    {formatTime(countdown)}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="success">
                    <div style={{ color: "red", fontSize: 40 }}>Time's Up!</div>
                    <div style={{ fontWeight: "bold" }}>
                      The verification OTP has expired.
                    </div>
                  </div>
                </div>
              )}
              <input
                className="inpt"
                type="number"
                value={otp}
                placeholder="Enter the OTP here..."
                onChange={(e) => setOtp(e.target.value)}
                style={{ width: "100%", marginTop: 10 }}
              />
              {<div style={{ color: "red", marginTop: 8 }}>{otpError}</div>}
              <button className="btn-forgot" onClick={handleOTPVerification}>
                Verify OTP
              </button>{" "}
            </>
          ) : (
            <>
              <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                Please enter your new password:
              </Typography>
              <input
                className="inpt"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: "100%", marginTop: 10 }}
              />
              <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                Confirm your new password:
              </Typography>
              <input
                className="inpt"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{ width: "100%", marginTop: 10 }}
              />
              {<div style={{ color: "red", marginTop: 8 }}>{error}</div>}
              <button
                style={{ marginTop: "18px" }}
                className="btn-forgot"
                onClick={handleChangePassword}
              >
                Change Password
              </button>
            </>
          )}
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}
